import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getContactUs = createAsyncThunk('contactus/getData', async params => {
    const response = await axios.get(`/contactus`, { params: params });
    const data = response?.data?.data;
    console.log('data :>> ', data);
    return {
        params,
        data: data?.data,
        totalPages: Math.ceil(data?.totalItems / params.perPage),
        perPage: data?.perPage,
        currentPage: data?.currentPage,
        totalItems: data?.totalItems
    };
});

export const appContactUsSlice = createSlice({
    name: 'contactus',
    initialState: {
        data: [],
        total: 1,
        params: {},
        allData: [],
        selectedUser: null
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getContactUs.fulfilled, (state, action) => {
                console.log('action :>> ', action);
                state.data = action.payload.data;
                state.params = action.payload.params;
                state.total = action.payload.totalPages;
                state.currentPage = action.payload.currentPage;
                state.totalItems = action.payload.totalItems;
            });
    }
});

export default appContactUsSlice.reducer;