import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const user = JSON.parse(localStorage.getItem('userData'));
const token = user?.accessToken;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common = { Authorization: `bearer ${token}` };
import { filterData } from '@src/common/dataTableFilter';

// Thunks
export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
    const response = await axios.get('/api/users/list/all-data');
    return response.data;
});

export const getData = createAsyncThunk('appUsers/getData', async params => {
    const response = await axios.get(`/users/list`, { params: params });
    const datas = await filterData(response.data.userdata, params);
    return {
        params,
        data: response?.data?.userdata,
        totalPages: response?.data?.userdata?.totalPages || 1,
    };
});

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
    const response = await axios.get(`/user/${id}`);
    return {
        data: response.data.userData,
        following: response.data.followingCount,
        follower: response.data.followerCount,
    };
});

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
    await axios.post('/apps/users/add-user', user);
    await dispatch(getData(getState().appUsers.params));
    await dispatch(getAllData());
    return user;
});

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
    await axios.delete(`/apps/users/delete/${id}`);
    await dispatch(getData(getState().appUsers.params));
    await dispatch(getAllData());
    return id;
});

// New: Update user data
export const updateUser = createAsyncThunk('appUsers/updateUser', async (user, { dispatch, getState }) => {
    const response = await axios.put(`/apps/users/update/${user.id}`, user);
    await dispatch(getData(getState().appUsers.params));
    return response.data;
});

// Slice
export const appUsersSlice = createSlice({
    name: 'appUsers',
    initialState: {
        data: [],
        follower: 0,
        following: 0,
        total: 1,
        params: {},
        allData: [],
        selectedUser: null,
        loading: false,
        error: null,
    },
    reducers: {
        clearSelectedUser(state) {
            state.selectedUser = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getAllData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllData.fulfilled, (state, action) => {
                state.loading = false;
                state.allData = action.payload;
            })
            .addCase(getAllData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data;
                state.params = action.payload.params;
                state.total = action.payload.totalPages;
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.selectedUser = action.payload;
            })
            .addCase(addUser.rejected, (state, action) => {
                state.error = action.error.message;
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.error = action.error.message;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                const updatedUser = action.payload;
                state.data = state.data.map(user =>
                    user.id === updatedUser.id ? updatedUser : user
                );
            });
    },
});

// Export actions
export const { clearSelectedUser } = appUsersSlice.actions;

export default appUsersSlice.reducer;
